import React from 'react';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { BASE_SERVICE } from '../../services';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
} from 'react-leaflet';

import {
  AcademicCapIcon,
  HomeModernIcon,
  MapIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import { ClipLoader } from 'react-spinners';
import { imageUrl } from 'utils/config';

const Map = (props) => {
  const openGmaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${props.data.latitude},${props.data.longitude}`;
    window.open(url, '_blank');
  };

  return (
    <MapContainer
      className="map-action"
      center={[Number(props.data.latitude), Number(props.data.longitude)]}
      zoom={13}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="bottomright" />
      <Marker
        position={[Number(props.data.latitude), Number(props.data.longitude)]}
      >
        <Popup>
          <div className="flex items-center justify-center flex-row w-full">
            <p className="">{props.data.nama}
            <br></br>
            </p>
          </div>
          <div className="flex items-center justify-center flex-row w-full">
            <p className="">{props.data.alamat}</p>
          </div>

          <div className="flex items-center justify-center flex-row w-full">
            <button
              onClick={openGmaps}
              className="bg-rose-600 p-2 rounded-md text-white"
            >
              Lihat di Google Maps
            </button>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

const DataDetail = () => {
  const [activeTab, setActiveTab] = React.useState('html');
  const [show, setShow] = React.useState(false);

  

  const { id } = useParams();

  const { isPending, data: perumDetail } = useQuery({
    queryKey: ['detailperum'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        {
          id,
        },
        `/perum/showById`,
      ).then((res) => res),
  });

  const { data: foto } = useQuery({
    queryKey: ['foto'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        {
          id,
        },
        `/perum/findPhoto`,
      ).then((res) => res),
  });

  const data = [
    {
      label: 'Sarana',
      value: 'sarana',
      icon: HomeModernIcon,
      desc: !isPending && (
        <div>
          <ul style={{ listStyle: 'square' }}>
            {perumDetail?.data?.values?.sarana.map((item, index) => {
              return <li key={index}>{item.nama_sarana}</li>;
            })}
          </ul>
        </div>
      ),
    },
    {
      label: 'Prasarana',
      value: 'prasarana',
      icon: AcademicCapIcon,
      desc: !isPending && (
        <div>
          <ul style={{ listStyle: 'square' }}>
            {perumDetail?.data?.values?.prasarana.map((item, index) => {
              return <li key={index}>{item.nama_sarana}</li>;
            })}
          </ul>
        </div>
      ),
    },
    {
      label: 'Untilitas',
      value: 'tentang',
      icon: WrenchScrewdriverIcon,
      desc: !isPending && (
        <div>
          <ul style={{ listStyle: 'square' }}>
            {perumDetail?.data?.values?.untilitas.map((item, index) => {
              return <li key={index}>{item.nama_sarana}</li>;
            })}
          </ul>
        </div>
      ),
    },
    {
      label: 'Lokasi',
      value: 'Lokasi',
      icon: MapIcon,
      desc: !isPending && <Map data={perumDetail?.data?.values?.data[0]} />,
    },
  ];

  if (isPending === true) {
    return (
      <div className="h-[80vh] w-full items-center justify-center flex">
        <ClipLoader
          color={`red`}
          loading={isPending}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

 

  // console.log(perumDetail?.data?.values, 'VL');

  return (
    <motion.div
      className="mb-12"
      initial={{ y: -200, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring' },
      }}
    >
      <div className="pt-16">
        <section className="bg-gray-100">
          <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-semibold drop-shadow-md">
              Data <span className="text-rose-800">Perumahan</span>
            </h1>
            <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
              <div className="lg:col-span-2 lg:py-12">
                <motion.div
                  className="w-full md:w-1/2"
                  initial={{ x: '-100vw' }}
                  animate={{ x: 0 }}
                  transition={{ duration: 1 }}
                >
                  <div className="order-1 mt-4 lg:order-3 w-[40vh] lg:w-[60vh] sm:w-[60vh] md:w-[60vh]">
                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      loop={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      className="mySwiper"
                    >
                      {foto?.data?.values?.data.map((item) => {
                        return (
                          <SwiperSlide key={item.id_foto}>
                            <img
                              style={{objectFit: 'cover'}}
                              src={`${imageUrl}/assets/${item.file}`}
                              alt="ima"
                              className="h-auto w-full object-contain"
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </motion.div>
              </div>

              <div className="rounded-lg p-4 bg-white shadow-lg lg:col-span-3 lg:p-12 flex justify-center flex-col">
                <h2 className="max-w-xl  text-lg padding-bottom: 30px">
                  Perumahan{' '}
                  <span className="text-rose-700">
                    {perumDetail?.data?.values?.data[0].nama}
                  </span>{' '}
                </h2>
                <div className="mt-4 flex flex-col">
                  <p>
                    <span className="font-semibold">
                      {perumDetail?.data?.values?.data[0].nama_dev}
                    </span>{' '}
                    <p>
                      {perumDetail?.data?.values?.data[0].alamat},{' '}
                      {perumDetail?.data?.values?.data[0].nama_kecamatan},{' '}
                      {perumDetail?.data?.values?.data[0].nama_desa}
                    </p>
                  </p>
                </div>
                <div className="mt-8">
                  <button
                    onClick={() => {
                      let flex = Number(document.body.scrollHeight) / 2;
                      let hide = flex / 100;
                      window.scrollTo(
                        0,
                        Number(document.body.scrollHeight) / hide,
                      );
                      setShow((prev) => !prev);
                    }}
                    className="text-white bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:ring-rose-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-rose-600 dark:hover:bg-rose-700 focus:outline-none dark:focus:ring-rose-800"
                  >
                    <span className="text-button-mobile">Lihat Site Plan</span>
                  </button>
                </div>
                <div className="mt-8">
                  <span className="bg-teal-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Subs {perumDetail?.data?.values?.data[0].subsidi}
                  </span>
                  <span className="bg-amber-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>{' '}
                    Kom {perumDetail?.data?.values?.data[0].komersil}
                  </span>
                  <a
                    target="_blank"
                    href={`https://wa.me/${perumDetail?.data?.values?.data[0].hp}`}
                    className="bg-green-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                      />
                    </svg>
                    Hubungi
                  </a>
                </div>
               
                <div className="mt-8"></div>
              </div>
            </div>
            <div className="mt-5"></div>
            <div className="rounded-lg p-4 bg-white shadow-lg lg:col-span-3 lg:p-12">
              <Tabs value={activeTab}>
                <TabsHeader
                  className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                  indicatorProps={{
                    className:
                      'bg-transparent border-b-2 border-blue-700 shadow-none rounded-none',
                  }}
                >
                  {data.map(({ label, value, icon }) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={() => setActiveTab(value)}
                      className={
                        activeTab === value
                          ? 'border-blue-700 text-blue-800 '
                          : ''
                      }
                    >
                      <div className="flex items-center gap-2">
                        {React.createElement(icon, { className: 'w-5 h-5' })}
                        {label}
                      </div>
                    </Tab>
                  ))}
                </TabsHeader>
                <TabsBody>
                  {data.map(({ value, desc }) => (
                    <TabPanel key={value} value={value}>
                      {desc}
                    </TabPanel>
                  ))}
                </TabsBody>
              </Tabs>
            </div>
          </div>
        </section>
      </div>

      <div
        onClick={() => {
          setShow(false);
        }}
        className={`${
          show ? 'flex' : 'hidden'
        } top-0 w-full items-center justify-center absolute h-full bg-[rgba(0,0,0,0.5)] z-50`}
      >
        <div>
          <img
            src={`${imageUrl}/assets/${foto?.data?.values?.dataSiteplan ? foto?.data?.values?.dataSiteplan.file : ''}`}
            alt="ok"
          />sdsdsd
        </div>
      </div>
    </motion.div>
  );
};

export default DataDetail;
