import React from 'react';
import { motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { BASE_SERVICE } from 'services';
import { imageUrl } from 'utils/config';
import { Link } from 'react-router-dom';

export default function Download() {
  const { data: dokumen, refetch } = useQuery({
    queryKey: ['dokumen'],
    queryFn: async () =>
      await BASE_SERVICE._onGetServiceMethod(`/document/findalldocument`).then(
        (res) => res,
      ),
  });
  return (
    <motion.div
      initial={{ y: -200, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring' },
      }}
      className="min-h-[100vh] w-full mt-16 parent justify-center flex"
    >
      <div className="top-16 relative overflow-x-auto w-[75%]">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nama Dokumen
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Informasi
              </th>
            </tr>
          </thead>
          <tbody>
            {dokumen?.data?.values?.map((item) => {
                return(
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                            {item.nama}
                        </th>
                        <td className="px-6 py-4 text-center">
                            <Link target="_blank" to={`${imageUrl}/assets/${item.file}`} className="p-2 bg-blue-600 text-white rounded-md" download>
                                Download
                            </Link>
                        </td>
                    </tr>
                )
            })}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
}
