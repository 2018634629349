import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import bannerImg from '../../assets/images/logo.png';
import { BASE_SERVICE } from 'services';

const DaftarPage = () => {
  const [form, setForm] = useState({
   nama: '',
    username: '',
    hp: '',
    password: '',
    email: '',
  });

  useEffect(() => {
  
  }, [form]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    console.log(form,"form")
    const resp = await BASE_SERVICE._onGetService(form, `/user/register`).then(
      (res) => res,
    );

    if (resp.status === 200) {
      alert('Success to create user!');
    }
  };

  return (
    <motion.div
      className="mb-12"
      initial={{ y: -200, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring' },
      }}
    >
      <div className="pt-16">
        <section className="bg-white">
          <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
            <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
              <img
                alt="Night"
                src="https://images.unsplash.com/photo-1617195737496-bc30194e3a19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                className="absolute inset-0 h-full w-full object-cover opacity-80"
              />

              <div className="hidden lg:relative lg:block lg:p-12">
                <a className="block text-white" href="/">
                  <span className="sr-only">Home</span>
                  <div className="order-1 pt-10 lg:order-3 lg:pt-100 md:pt-0 sm:pt-0 align-right">
                    <img src={bannerImg} alt="Banner MSN PRODUCTION"></img>
                  </div>
                </a>

                <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                  Welcome to Si Super
                </h2>

                <p className="mt-4 leading-relaxed text-white/90">
                  Form pendaftaran Developer Dinas Perumahan Rakyat dan Kawasan Permukiman Kabupaten Ngawi.
                </p>
              </div>
            </section>

            <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
              <div className="max-w-xl lg:max-w-3xl">
                <h1 className="text-4xl font-semibold drop-shadow-md">
                  Register to <span className="text-rose-800">Pengembang</span>
                </h1>

                <form action="#" className="mt-8 grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nama Developer
                    </label>

                    <input
                      type="text"
                      id="nama"
                      value={form.nama}
                      name="nama"
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                     
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nomor Telpon
                    </label>

                    <input
                      type="text"
                      id="hp"
                      value={form.hp}
                      name="hp"
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6">
                    <label
                      
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>

                    <input
                      type="email"
                      id="Email"
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                     
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username
                    </label>

                    <input
                      type="text"
                      id="username"
                      value={form.username}
                      name="username"
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      for="PasswordConfirmation"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>

                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
                    />
                  </div>

                  <div className="col-span-6">
                    <label for="MarketingAccept" className="flex gap-4">
                      <input
                        type="checkbox"
                        id="MarketingAccept"
                        name="marketing_accept"
                        className="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm"
                      />

                      <span className="text-sm text-gray-700">
                        I want to receive emails about events, product updates
                        and company announcements.
                      </span>
                    </label>
                  </div>

                  <div className="col-span-6">
                    <p className="text-sm text-gray-500">
                      By creating an account, you agree to our
                      <a href="#" className="text-gray-700 underline">
                        terms and conditions
                      </a>
                      and
                      <a href="#" className="text-gray-700 underline">
                        privacy policy
                      </a>
                      .
                    </p>
                  </div>

                  <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                    <button
                      onClick={handleClick}
                      className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                    >
                      Create an account
                    </button>

                    <p className="mt-4 text-sm text-gray-500 sm:mt-0">
                      Already have an account?
                      <a href="#" className="text-gray-700 underline">
                        Log in
                      </a>
                      .
                    </p>
                  </div>
                </form>
              </div>
            </main>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default DaftarPage;