import React from 'react';

const PaginationComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  totalItems,
  itemsPerPage,
}) => {
  const getPages = () => {
    const pages = [];
    let start = Math.max(1, currentPage - 2);
    let end = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 3) {
      end = Math.min(5, totalPages);
    } else if (currentPage >= totalPages - 2) {
      start = Math.max(1, totalPages - 4);
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pages = getPages();
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="flex flex-col items-center my-4">
      <div className="text-sm text-gray-700 mb-2">
        Menampilkan {startItem} - {endItem} dari {totalItems}
      </div>
      <nav className="flex justify-center">
        <ul className="flex list-none flex-wrap">
          <li
            className={`px-3 py-2 mx-1 mb-2 rounded-lg ${
              currentPage === 1
                ? 'cursor-not-allowed text-gray-400 font-bold'
                : 'cursor-pointer text-rose-600 hover:bg-rose-100 font-bold'
            }`}
            onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
          >
            {'<'}
          </li>
          {pages.map((page) => (
            <li
              key={page}
              className={`px-3 py-2 mx-1 mb-2 rounded-lg ${
                currentPage === page
                  ? 'bg-rose-500 text-white font-semibold'
                  : 'cursor-pointer text-rose-600 hover:bg-rose-100 font-semibold'
              }`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </li>
          ))}
          <li
            className={`px-3 py-2 mx-1 mb-2 rounded-lg ${
              currentPage === totalPages
                ? 'cursor-not-allowed text-gray-400 font-bold'
                : 'cursor-pointer text-rose-600 hover:bg-rose-100 font-bold'
            }`}
            onClick={() =>
              currentPage !== totalPages && onPageChange(currentPage + 1)
            }
          >
            {'>'}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationComponent;
