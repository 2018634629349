import React, { useState, useEffect } from 'react';

import AlurInformasi from './components/AlurInformasi';

const BlogIndex = () => {
 
  return (
    <div className="py-16 parent">
      
        <AlurInformasi />
    </div>
  );
};

export default BlogIndex;
