import React from "react";
import "./BottomLine.css";

const BottomLine = () => {
  return (
    <>
      <div className='flex  mt-4'>
        <div className='side-line'></div>
        <div className='side-line'></div>
        <div className='middle-line'></div>
      </div>
    </>
  );
};

export default BottomLine;
