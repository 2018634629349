import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import logo from "../../../assets/images/dinas2.png";
import { ImCross } from "react-icons/im";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "../../../pages/shared/Shared.css";

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navLinks = [
    { title: 'Beranda', link: '/' },
    { title: 'Perumahan Pengembang', link: '/data' },
    { title: 'Informasi', link: '/blog' },
    { title: 'Kontak', link: '/kontak' },
    { title: 'Galeri', link: '/galeri' },
    { title: 'Login', link: 'https://sisuper.kabngawi.id/' },
  ];
  const activeLink = ({ isActive }) => {
    return {
      fontWeight: 500,
      color: isActive && 'white',
    };
  };

  // Show Navbar on Scroll UP
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(true);
        } else {
          setShow(false);
        }
        setLastScrollY(window.scrollY);
      }
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div
      className={`visible ${show && 'nav-hidden'} shadow-lg bg-gray-800  
     z-20`}
    >
      <div className='flex items-center justify-between w-full px-3 py-3 md:px-24'>
        <div>
          <Link to='/'>
            <img className='2xl:max-w-[30%] sm:max-w-[32%] max-w-[40%] pr-10' src={logo} alt='MSN PRODUCTION Logo' />
          </Link>
        </div>
        <div>
          <ul className='items-center hidden lg:flex'>
            {navLinks.map((navItem) => (
              <li className='mx-4' key={navItem.title}>
                <NavLink
                  to={navItem.link}
                  style={activeLink}
                  className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white whitespace-nowrap'
                >
                  {navItem.title}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className='block lg:hidden'>
            <button onClick={toggleDrawer} className='text-white hover:text-red'>
            <FaBars></FaBars>
            </button>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction='right'
              style={{ backgroundColor: 'rgb(31 41 55 / var(--tw-bg-opacity))' }}
              className='flex flex-col justify-between pb-4 bla'
            >
              <ul className=''>
                <li className='mt-6 mb-10 ml-4'>
                  <ImCross
                    className='duration-300 cursor-pointer hover:text-red'
                    onClick={() => setIsOpen(!isOpen)}
                  ></ImCross>
                </li>
                {navLinks.map((navItem) => (
                  <li
                    className='m-4'
                    key={navItem.title}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <NavLink
                      to={navItem.link}
                      style={activeLink}
                      className='flex items-center duration-300 text-white hover:text-secondary'
                    >
                      <span className='mr-3'>{navItem.icon}</span>
                      <span>{navItem.title}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
}
