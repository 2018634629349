export const generateDate = (date) => {
    // Membuat objek Date dari tanggal awal
    const tanggalObjek = new Date(date);

    // Array untuk menyimpan nama bulan
    const namaBulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

    // Mendapatkan tanggal, bulan, dan tahun dari objek Date
    const tanggal = tanggalObjek.getDate();
    const bulan = namaBulan[tanggalObjek.getMonth()];
    const tahun = tanggalObjek.getFullYear();

    // Menggabungkan menjadi format yang diinginkan
    const tanggalAkhir = tanggal + " " + bulan + " " + tahun;

    return tanggalAkhir
}