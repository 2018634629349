import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { imageUrl } from 'utils/config';
import { BASE_SERVICE } from 'services';

const BeritaDetail = () => {
  const [beritaDetail, SetBeritaDetail] = useState({});
  const beritaID = window.location.pathname.split('/').pop();
  const onGetBeritaDetail = () => {
    const payloadBerita = {
      IdKonten: beritaID,
    };
    BASE_SERVICE._onGetService(payloadBerita, `/konten/showById`)
      .then((res) => {
        if (res?.data?.code === 200) {
          SetBeritaDetail(res?.data?.values[0]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          localStorage.removeItem('accessToken');
        }
      });
  };

  useEffect(() => {
    onGetBeritaDetail();
  }, []);

  return (
    // <div className="container mx-auto p-4">
    //   <img
    //     src={`${imageUrl}/assets/${beritaDetail.gambar}`}
    //     alt={beritaDetail.gambar}
    //     width={'80%'}
    //     className="w-[80%] h-['90%'] object-cover flex items-center justify-center"
    //   />
    <div className="min-h-screenflex flex-col items-center">
      <div className="relative w-full max-w-screen-lg mt-8">
           <img
         src={`${imageUrl}/assets/${beritaDetail.gambar}`}
         alt={beritaDetail.gambar}
         width={'80%'}
         className="w-4/5 mx-auto block"
       />
      </div>
      <div className="p-4">
        <h1 className="text-3xl font-bold mb-2">{beritaDetail.judul}</h1>
        <p className="text-gray-600 mb-4">{beritaDetail.create_at}</p>
        <p
          className="text-gray-700 leading-relaxed"
          dangerouslySetInnerHTML={{
            __html: beritaDetail.deskripsi,
          }}
        ></p>
      </div>
    </div>
  );
};

export default BeritaDetail;
