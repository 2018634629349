import React,{useState,useEffect} from 'react'

export default function Tag(props) {
    const [isHave, setIsHave] = useState(false);

    useEffect(() => {
        const isHaves = props.isTags === props.child
        setIsHave(isHaves);
    },[props.isTags])

    if(isHave === true){
        return (
            <div 
                onClick={() => props.onClick(props.child)}
                className={`border-2
                    w-32 py-4 text-center bg-rose-600
                    text-white border-rose-600 rounded-full transition ease-in-out delay-150`
                }>
                    {props.child}
            </div>
        )
    }

  return (
    <div 
        onClick={() => props.onClick(props.child)}
        className={`border-2 
            border-stone-600 w-32 py-4 text-center hover:bg-rose-600 transition ease-in-out delay-150
            hover:text-white text-black hover:border-rose-600 rounded-full`
        }>
            {props.child}
    </div>
    )
}
