import React , {useState, useEffect}from 'react';
import { Link } from 'react-router-dom';
// import { FaAngleRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import bannerImg from '../../../assets/images/logo.png';
// import logodin from '../../../assets/images/dinas2.png';
import '../../../pages/shared/Shared.css';
// import { SecondaryBtn } from '../../../components';

const Banner = () => {
  const [bg, SetBG] = useState('bg-slide1');

  useEffect(() => {
    const background = ['bg-slide1', 'bg-slide2', 'bg-slide3', 'bg-slide4', 'bg-slide5']
    let index = 0;

    const onChangeBackground = () => {
      SetBG(background[index])
      index = (index + 1 ) % background.length;
    };
    
    // change background every 4 second
    const intervalId = setInterval(onChangeBackground, 7000);

    return () => clearInterval(intervalId)
  }, []);

  return (
    <section className={`${bg} "relative bg-cover bg-center bg-no-repeat"`}>
      {/* <div className="absolute inset-0 bg-white/600 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div> */}

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <motion.div
          className="w-full md:w-1/2"
          initial={{ x: '100vw' }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h1 className="text-3xl font-extrabold sm:text-5xl text-lime-50">
              Si Super
              <strong className="block font-extrabold text-rose-500 text-stroke">
                Dinas Perumahan Rakyat dan Kawasan Permukiman Kabupaten Ngawi.
              </strong>
            </h1>
            <p className="mt-4 max-w-lg font-bold sm:text-xl">
              Kemudahan di era Digitalisasi kami berharap dengan adanya Si Super
              dapat meningkatkan pelayan serta menyajikan data terkait perumahan
              yang ada di wilayah Kabupaten Ngawi
            </p>
					<motion.div
          initial={{ x: '-10vw' }}
          animate={{ x: 1 }}
          transition={{ duration: 1 }}
        	>
            <div className="mt-8 flex flex-wrap gap-4 text-center">
              <Link
                to="/data"
                className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                <span className="text-button-mobile">Cek Perumahan</span>
              </Link>
              <Link
                to="https://sisuper.kabngawi.id/"
                className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                <span className="text-button-mobile">Login</span>
              </Link>
              <a
                href="/daftar"
                className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow  hover:border-rose-600 hover:bg-rose-500 hover:text-black  focus:ring active:text-rose-500 sm:w-auto"
              >
                Daftar Developer
              </a>
            </div>
						</motion.div>
          </div>
        </motion.div>
        
          {/* <div className="order-1 pt-10 lg:order-3 lg:pt-100 md:pt-0 sm:pt-0 align-right">
            <img src={bannerImg} alt="Banner MSN PRODUCTION"></img>
          </div> */}
        
      </div>
    </section>
  );
};

export default Banner;
