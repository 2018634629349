import { API_URL, BASE_PATH } from '../config';
import Axios from 'axios';

const timeout = 12000;
const p = 'post';
const g = 'get';

export const BASE_SERVICE = {
  async _onGetService(request, urlService) {
    return Axios(`${API_URL}${BASE_PATH}${urlService}`, {
      method: p,
      data: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
    });
  },
  async _onGetServiceMethod(urlService) {
    return Axios(`${API_URL}${BASE_PATH}${urlService}`, {
      method: g,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
    });
  },
};
