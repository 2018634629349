import React from "react";
import ImageBanner from "../../../../assets/images/visual-banner.svg";
import { useQuery } from "@tanstack/react-query";
import { BASE_SERVICE } from "services";
import { BottomLine } from "../../../../components";
import { Link } from "react-router-dom";

function AlurInformasi() {
  const { data: syarat, refetch: refetchSyarat } = useQuery({
    queryKey: ["syarat"],
    queryFn: async () =>
      await BASE_SERVICE._onGetServiceMethod(`/syarat/findallsyarat`).then(
        (res) => res
      ),
  });

  return (
    <div className="my-8">
      <h1 className="text-3xl font-semibold ">
        Alur <span className="text-rose-700">Informasi</span>
      </h1>
      <BottomLine />
      <div className="flex justify-between items-center flex-row">
        {/* <div className="w-[45%] flex items-center justify-center">
          <img
            className="object-cover w-[85%] h-[40%]"
            src={ImageBanner}
            alt="Banner"
            width={300}
          />
        </div> */}
        <div className="w-full">
        <img
            className="object-cover"
            src={ImageBanner}
            alt="Banner"
            width={300}
          />
          <div className="my-8 flex text-justify flex-col">
            <h1 className="font-semibold text-xl mb-2">INFORMASI</h1>
            <span>
              SETIAP PENGEMBANG / DEVELOPER YANG AKAN MENYELENGGARAKAN PERUMAHAN
              WAJIB MENYUSUN RENCANA TAPAK (SITE PLAN) SERTA MENYERAHKAN PSU
              (PRASARANA, SARANA DAN UTILITAS) YANG DIBANGUN KE PEMERINTAH
              DAERAH. HAL INI DIATUR DALAM PERATURAN BUPATI NGAWI NOMOR 4 TAHUN
              2024 TENTANG PERUBAHAN ATAS PERATURAN BUPATI NGAWI NOMOR 186 TAHUN
              2022 TENTANG PENYELENGGARAAN PERUMAHAN
            </span>
          </div>

          <h1 className="font-semibold text-xl mb-2">
            SYARAT PENGAJUAN PENGESAHAN RENCANA TAPAK (SITE PLAN) PERUMAHAN
          </h1>

          {syarat?.data?.values?.map((item) => {
            return (
              <p className="text-base my-2" key={item.id_syarat}>
                <ul class="list-disc">
                  <li>{item.nama}</li>
                </ul>
              </p>
            );
          })}

          <h1 className="font-semibold text-xl mb-2">
            SYARAT PENYERAHAN PRASARANA, SARANA DAN UTILITAS (PSU) PERUMAHAN KE
            PEMERINTAH DAERAH
          </h1>
          <p>
            <ul class="list-decimal">
              <li>
                DOKUMEN RENCANA TAPAK (SITE PLAN) YANG SUDAH MENDAPATKAN
                PENGESAHAN DARI PEMERINTAH DAERAH;
              </li>
              <li>
                PERSETUJUAN BANGUNAN GEDUNG, BAGI BANGUNAN YANG DIPERSYARATKAN;
              </li>
              <li>
                SURAT PELEPASAN HAK ATAS TANAH DARI PENGEMBANG KEPADA PEMERINTAH
                DAERAH;
              </li>
              <li>KTP PEMOHON;</li>
              <li>AKTA PENDIRIAN BADAN USAHA; DAN</li>
              <li>FORMULIR PENYERAHAN PRASARANA, SARANA DAN UTILITAS (PSU).</li>
            </ul>
          </p>

          <div className="mt-8">
            <Link
              to="/download"
              className="bg-rose-600 text-white p-4 rounded-md"
            >
              Download File
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlurInformasi;
